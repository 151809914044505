import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import Layout from "@components/Layout";

interface Props {
  children?: React.ReactNode;
}

const NotFoundPage: React.FC<Props> = () => {
  return (
    <Layout>
      <Grid
        container
        className="NotFoundPage-container"
        style={{
          border: "0px solid red",
          marginTop: 1,
          height: "30vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
          style={{ padding: 1, border: "0px solid black" }}
        >
          <Grid
            container
            style={{
              gap: 1,
              padding: 1,
              border: "0px solid red",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid item>
              <ReportProblemOutlinedIcon
                className="WarningAmberOutlinedIcon"
                fontSize="large"
                style={{
                  fontSize: "6rem",
                  color: "black",
                }}
              />
            </Grid>
            <Grid item style={{ border: "0px solid blue", color: "red" }}>
              <Typography variant={"h6"}>
                <b>
                  Página não encontrada ou você não possui acesso a este
                  recurso.
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default NotFoundPage;
