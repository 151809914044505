/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import {
  AuthCogCookieKeys,
  AuthenticationContextData,
  AuthenticationResponse,
  CognitoParsedToken,
} from "./IAuthContext";

import { IHttpService } from "@modules/infra/http/models/IHttpService";

import Loading from "@components/Loading";
import appConfig from "@config/appConfig";
import { Types } from "@ioc/types";
import { goToLogout, toReplaceAsterisk } from "@utils/index";

const BUFFER_TIME_MS = 59 * 60 * 1e3;
const TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS = 30e3;

export enum Roles {
  ADMINMODE = "admin_portal_comercial",
  ADMIN = "admin",
  ADMINPORTAL = "ADMINPORTAL",
}

export const AuthContext = createContext<AuthenticationContextData | null>(
  null
);

const authServiceStandalone = axios.create({
  baseURL: appConfig.api.urlCognito.production,
  timeout: appConfig.api.timeout,
});

interface PropsAuthProvider {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<PropsAuthProvider> = (
  props: PropsAuthProvider
) => {
  const [
    currentAuthState,
    setCurrentAuthState,
  ] = useState<AuthenticationContextData | null>(null);

  const iocContext = useIoCContext();
  const httpService = iocContext.serviceContainer.get<IHttpService>(
    Types.IHttpService
  );

  // Função para fazer a solicitação de atualização do token
  const refreshTokenRequest = async (
    currentTokenRefresh: string
  ): Promise<AuthenticationResponse> => {
    const { data: refreshTokenResponse } = await authServiceStandalone.post<
      AuthenticationResponse
    >("/auth/refresh", {
      token: currentTokenRefresh,
    });

    return refreshTokenResponse;
  };

  // Função para fazer de atualização do token
  const setTokenExpiration = useCallback(
    async (nextTokenRefreshTime: number): Promise<string | null> => {
      try {
        const data = await tokenRefresh(
          nextTokenRefreshTime - TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS
        );
        if (data) {
          const builtToken = `${data.TokenType} ${data.AccessToken}`;
          httpService.setAuthorization(builtToken);
          return builtToken;
        }
        return null;
      } catch (error) {
        console.error(
          "[setTokenExpiration]: Failed to refresh access token in setTokenExpirationStrategy",
          error
        );
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Função principal de atualização do token
  const tokenRefresh = useCallback(
    async (refreshTokenExpirationTime?: number) => {
      try {
        const cookies = new Cookies();
        const cookiesTokenRefresh = cookies.get(AuthCogCookieKeys.refreshToken);

        // Verifica se o token atual existe no cookies da sessao
        if (!cookiesTokenRefresh) {
          throw new Error("Refresh token not found");
        }

        // Verifica se o token do cookie atual da sessao ainda não expirou
        if (
          refreshTokenExpirationTime &&
          +new Date() <= refreshTokenExpirationTime
        ) {
          return null;
        }

        const refreshTokenResponse = await refreshTokenRequest(
          cookiesTokenRefresh
        );
        const nextTokenRefreshTime =
          jwtDecode<CognitoParsedToken>(refreshTokenResponse.AccessToken).exp *
            1e3 -
          BUFFER_TIME_MS;
        httpService.setTokenExpirationStrategy(
          async () => await setTokenExpiration(nextTokenRefreshTime)
        );
        return refreshTokenResponse;
      } catch (error) {
        console.error("[tokenRefresh]: Failed to refresh access token", error);
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(async () => {
    goToLogout();
  }, []);

  const bootstrapAuthentication = useCallback(async () => {
    try {
      const data = await tokenRefresh();
      if (!data) {
        await logout();
        return;
      }

      httpService.setAuthorization(`${data.TokenType} ${data.AccessToken}`);

      let authData: AuthenticationContextData = {
        email: data.meta.email,
        name: data.meta.name,
        groups: data.meta.groups,
        refreshToken: data.RefreshToken,
        listCNPJ: data.meta.permissionSet.CNPJ,
        subject: data.meta.id,
        token: data.AccessToken,
        adminMode: data.meta.groups.includes(Roles.ADMINMODE),
        hasAdminRole: data.meta.groups.includes(Roles.ADMINMODE),
        username: data.meta.username,
        userID: data.meta.id,
        isAdmin: Boolean(
          data.meta.permissionSet.ROLES.some((role) =>
            role.includes(Roles.ADMIN)
          )
        ),
        bpID: `${data.meta.permissionSet.BPID}`,
        listAssessorIDAccepted: data.meta.permissionSet.PORTFOLIO_IDS,
        roles: data.meta.permissionSet.ROLES,
        sub: data.meta.id,
        type: data.meta.permissionSet.ROLES.includes(Roles.ADMIN)
          ? "admin"
          : "advisorID",
        SYSTEM_MODULES: data.meta.permissionSet.SYSTEM_MODULES.filter((item) =>
          item.includes("portal_comercial")
        ),
        logout,
        authByPermission,
      };

      setCurrentAuthState(authData);
    } catch (error) {
      console.error("[bootstrapAuth]: Failed to refresh access token", error);
      await logout();
    }
  }, []);

  {
    /* Manipula estaticamente as permissoes [Ainda Em fase de Desenvolvimento - 29/11/2023] - sera excluido em definitivo antes da entrega final */
  }
  // const addRoles = (auth:AuthenticationContextData):AuthenticationContextData => {
  //     auth = {
  //       ...auth,
  //       roles: ["portal_comercial"],
  //       isAdmin: false,
  //       SYSTEM_MODULES: [
  //         "portal_comercial:costs:simulation", // simular preco
  //         "portal_comercial:appointment:price", // consultar preços
  //         "portal_comercial:appointment:customer-price", // consultar preços de cliente
  //         "portal_comercial:appointment:sales" // consultar vendas
  //       ],
  //     };
  //     return auth;
  // }

  const authByPermission = useCallback(
    (
      userPermission: string[],
      isAdmin: boolean,
      checkPermission: string
    ): boolean => {
      if (!userPermission) return false; // tratativa para quando userPermission for undefined

      const replacedAsterik = toReplaceAsterisk(checkPermission);

      // Verifica se a permissao consta na lista
      const hasUserPermission = userPermission
        .map((p) => (p.includes("*") ? p.replace("*", "") : p)) // ajuste necessario para aceitar o padrao -> "portal_comercial:costs:settings-active:*"
        .filter((permissionUser) =>
          replacedAsterik // Filtra as todas as permissoes - read, write
            .some((replacedPermission) =>
              replacedPermission.includes(permissionUser)
            )
        );

      let [checked] = hasUserPermission.length === 0 ? [false] : [true];
      return isAdmin || checked;
    },
    []
  );

  useEffect(() => {
    bootstrapAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={currentAuthState}>
      {currentAuthState && currentAuthState.token ? (
        props.children
      ) : (
        <Loading />
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
