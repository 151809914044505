import Layout from "@components/Layout";
import Title from "@components/Title";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import GridOnIcon from "@material-ui/icons/GridOn";
import { IDownloadExcelWalletAssessorService } from "@modules/user/models/IDownloadExcelWalletAssessorService";
import AppError from "@utils/AppError";
import { documentMask, downloadFile, formatCurrency } from "@utils/index";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import formatTZ from "date-fns-tz/format";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

const utcZone = "Europe/Lisbon";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      positiveValue: { color: theme.palette.success.main },
      negativeValue: { color: theme.palette.primary.main },
    })
);

const AppointmentWallet: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const downloadExcelWalletService = iocContext.serviceContainer.get<
    IDownloadExcelWalletAssessorService
  >(Types.Users.IDownloadExcelWalletAssessorService);

  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadExcel = useCallback(async () => {
    try {
      setLoadingExcel(true);

      const [url, fileName] = await downloadExcelWalletService.execute({
        partnerID: userState.state.bpID,
      });
      downloadFile(url, fileName);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Ocorreu um erro ao baixar arquivo excel, tente novamente.",
        { variant: "error" }
      );
    } finally {
      setLoadingExcel(false);
    }
  }, [downloadExcelWalletService, enqueueSnackbar, userState.state.bpID]);

  const columns: GridColDef[] = [
    { field: "customerID", headerName: "id", width: 100 },
    {
      field: "CNPJ",
      headerName: "CNPJ",
      width: 150,
      valueFormatter: (props) =>
        documentMask(props.getValue(props.id, props.field) as string),
    },
    { field: "customerStateId", headerName: "Incrição Estadual", width: 250 },
    { field: "companyName", headerName: "Nome", width: 320 },
    { field: "customerGroupID", headerName: "Grupo Cliente ID", width: 150 },
    { field: "customerGroupDesc", headerName: "Grupo Cliente", width: 150 },
    { field: "segment", headerName: "Segmento", width: 150 },
    { field: "segmentDesc", headerName: "Descrição do segmento", width: 250 },
    { field: "center", headerName: "Centro", width: 120 },
    {
      field: "salesAreaFormatted",
      headerName: "Área de vendas",
      width: 150,
      valueGetter: (params) => {
        return {
          salesAreaDesc: params.getValue(params.id, "salesAreaDesc"),
          salesArea: params.getValue(params.id, "salesArea"),
        };
      },
      valueFormatter: (params) => {
        const sale = params.getValue(params.id, "salesAreaFormatted") as {
          salesAreaDesc: string;
          salesArea: string;
        };

        return `${sale.salesArea} - ${sale.salesAreaDesc}`;
      },
    },
    { field: "state", headerName: "Estado", width: 150 },
    { field: "phone", headerName: "Telefone", width: 150 },
    { field: "email", headerName: "email", width: 250 },
    { field: "advisorID", headerName: "Assessor ID", width: 160 },
    { field: "advisorDesc", headerName: "Nome do assessor", width: 250 },
    { field: "managerCode", headerName: "Gerente ID", width: 160 },
    { field: "managerName", headerName: "Nome do gerente", width: 250 },
    { field: "analystID", headerName: "Analista ID", width: 160 },
    { field: "analystDesc", headerName: "Nome do analista", width: 250 },
    {
      field: "first_nf",
      headerName: "Primeira NF",
      width: 160,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "last_nf",
      headerName: "Última NF",
      width: 160,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "noPurchaseDays",
      headerName: "Dias sem compras",
      width: 200,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "totalCredit",
      headerName: "Limite de crédito",
      width: 200,
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "usedLimit",
      headerName: "Em aberto",
      width: 160,
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
      cellClassName: (params) =>
        clsx({
          [classes.negativeValue]: (params.value as number) > 0,
        }),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "availableLimit",
      headerName: "Limite disponível",
      width: 200,
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
      cellClassName: (params) =>
        clsx({
          [classes.positiveValue]: (params.value as number) > 0,
        }),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "createdAt",
      headerName: "Cadastrado em",
      width: 180,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    { field: "statusType", headerName: "Status", width: 120 },
    { field: "statusDesc", headerName: "Descrição de status", width: 150 },
    {
      field: "isParticipatingInTheCurrentMarketingPlan",
      headerName: "Plano Marketing",
      width: 180,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field) ? "Sim" : "Não",
    },
  ];

  return (
    <Layout enableMargins>
      <Title title="Consultar carteira" />
      <Grid container justify="center" alignItems="center">
        <Button
          variant="contained"
          startIcon={<GridOnIcon />}
          style={{ margin: "1rem" }}
          color="primary"
          onClick={downloadExcel}
          endIcon={
            loadingExcel && (
              <CircularProgress
                style={{ height: "2.5rem", width: "2.5rem" }}
                color="secondary"
              />
            )
          }
        >
          Baixar Excel
        </Button>
      </Grid>
      <div style={{ height: "100%" }}>
        <DataGrid
          rows={userState.state.listCNPJ.map((cnpj, index) => ({
            ...cnpj,
            id: cnpj.customerID || index,
          }))}
          columns={columns}
          loading={userState.state.loadingCNPJ}
        />
      </div>
    </Layout>
  );
};

export { AppointmentWallet };
