import { UserType } from "keycloak-js";

export interface AuthenticationContextData {
  username: string;
  userID: string;
  token: string;
  name: string;
  refreshToken: string;
  subject: string;
  email: string;
  groups: string[];
  adminMode: boolean;
  hasAdminRole: boolean;
  listCNPJ: CNPJS[];
  SYSTEM_MODULES: string[];

  bpID: string;
  listAssessorIDAccepted: string[];
  roles: string[];
  sub: string;
  isAdmin: boolean;
  type: UserType;

  logout: () => Promise<void>;
  authByPermission: (
    user: string[],
    admin: boolean,
    checkPermission: string
  ) => boolean;
}

export enum AuthCogCookieKeys {
  refreshToken = "refreshToken",
}

export interface CNPJS {
  CNPJ: string;
  companyName: string;
  BpID?: string;
}

export interface AuthenticationResponseMeta {
  id: string;
  email: string;
  name: string;
  username: string;
  roles: string[];
  groups: string[];
  adminMode: boolean;
  hasAdminRole: boolean;
  custom_attributes: CustomAttributes;
  permissionSet: PermissionSet;
}

export interface PermissionSet {
  PORTFOLIO_IDS: string[];
  BPID: number;
  CNPJ: Cnpj[];
  ROLES: string[];
  SUB: string;
  LIST_ACESSOR_ID_ACCEPTED: string[];
  SYSTEM_MODULES: string[];
}

export interface Cnpj {
  CNPJ: string;
  companyName: string;
}
export interface CustomAttributes {
  portfolioIDs: string[];
  CNPJs: Cnpj[];
  customType: string;
  custombpID: string;
  customDocument: string;
  emailVerified: string;
  userStatus: string;
}

export interface AuthenticationResponse {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: "Bearer";
  RefreshToken: string;
  meta: AuthenticationResponseMeta;
}

export interface CognitoParsedToken {
  sub: string;
  "cognito:groups": string[];
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}
