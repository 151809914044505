import { AuthProvider } from "@context/AuthContext/AuthContext";
import { datadogRum } from "@datadog/browser-rum";
import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, IconButton, ThemeProvider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBRLocale from "date-fns/locale/pt-BR";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { ProviderContext, SnackbarProvider } from "notistack";
import React, { useRef } from "react";
import Routes from "./components/Routes";
import Theme from "./components/Theme";
import { IoCProvider } from "./context/IoCContext/IoCContext";
import { isDevelopment } from "@config/appConfig";
import { UserProvider } from "./context/UserContext";

import "./index.css";

const App: React.FC = () => {
  if (window.location.hostname !== "localhost") {
    datadogRum.init({
      applicationId: "094f2fce-4672-4425-b444-7957efd91ef6",
      clientToken: "pub1a4cffb24d6c229e516cd3f9ae68e091",
      site: "datadoghq.com",
      service: "portal-assessor",
      env: "prod-atem",
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }

  const notistackRef = useRef<ProviderContext>();
  const onClickDismiss = (key: string | number) => () => {
    notistackRef &&
      notistackRef.current &&
      notistackRef.current.closeSnackbar(key);
  };

  return (
    <FlagsmithProvider
      options={{
        environmentID:
          window.location.origin === "localhost" ||
          window.location.origin === "testecomercial" ||
          isDevelopment
            ? "abos4xNYzDTue3Hfy3ZVxF"
            : "PXmxxBYvYGe5sSLFZu8mQe",
        api: "https://flagsmith.ream.com.br/api/v1/",
      }}
      flagsmith={flagsmith}
    >
      <IoCProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
          <AuthProvider>
            <ThemeProvider theme={Theme}>
              <SnackbarProvider
                autoHideDuration={5000}
                maxSnack={3}
                preventDuplicate
                //@ts-ignore
                ref={notistackRef}
                action={(key) => (
                  <IconButton size="small" onClick={onClickDismiss(key)}>
                    <CloseIcon htmlColor="#fff" />
                  </IconButton>
                )}
              >
                <UserProvider>
                  <CssBaseline />
                  <Routes />
                </UserProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </IoCProvider>
    </FlagsmithProvider>
  );
};

export default App;
