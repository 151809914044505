import React, { PropsWithChildren } from "react";
import { Redirect } from "react-router-dom";

import { Roles, useAuth } from "@context/AuthContext/AuthContext";
import ROUTES from "@config/routes";

interface RouteGuardProps extends PropsWithChildren<{}> {
  children: any;
  rules?: (_: string) => boolean;
  flag?: string;
}

const RouteGuard: React.FC<RouteGuardProps> = ({ children, rules, flag }) => {
  const { token, SYSTEM_MODULES, roles } = useAuth();
  const isAdmin = roles.some(
    (role) => role === Roles.ADMIN || role === Roles.ADMINPORTAL
  );

  const isAuthorized = SYSTEM_MODULES.some((authPermission) => {
    return rules && rules(authPermission);
  });

  return (token && isAdmin) || isAuthorized ? (
    children
  ) : (
    <Redirect to={ROUTES.USER_ROUTES.NOTFOUND} />
  );
};

export default RouteGuard;
