import {
  AppBar,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Autocomplete } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../config/routes";
import { useUserDispatch, useUserState } from "../../context/UserContext";
import DrawerMenu from "../DrawerMenu";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      appbar: { boxShadow: "none" },
      buttonsToolbar: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          alignItems: "center",
          "& button:not(:first-child)": { marginLeft: "1rem" },
        },
      },
      autocompleteRoot: {
        width: 300,
        display: "none",
        [theme.breakpoints.up("md")]: { display: "block" },
      },
      autocompleteInput: { color: "white" },
      autocompleteInputLabelRoot: { color: "white !important" },
      autocompleteInputLabel: { color: "white" },
      autocompleteInputUnderline: {
        "&:before": { borderBottomColor: "white" },
        "&:after": { borderBottomColor: "white" },
      },
      matchText: { color: theme.palette.primary.main },
    })
);

const ButtonsToolbarUser: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const dispatchUser = useUserDispatch();
  const location = useLocation();

  return (
    <div
      className={classes.buttonsToolbar}
      style={{ border: "0px solid blue" }}
    >
      <Autocomplete
        options={userState.state.assessorList}
        className={classes.autocompleteRoot}
        getOptionLabel={(option) => `${option.bpID} - ${option.bpName}`}
        loading={userState.state.loadingAssessorList}
        value={userState.state.bpSelected}
        style={{
          display:
            location.pathname === ROUTES.USER_ROUTES.HOME ||
            location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_SALES
              ? "none"
              : "block",
        }}
        onChange={(e, value) =>
          dispatchUser({
            type: "APP_SET_ASSESSOR",
            payload: {
              bpID: value.bpID,
              type: value.type,
              bpName: value.bpName,
              userID: userState.state.userID,
            },
          })
        }
        disableClearable
        renderOption={(option, { inputValue }) => {
          const matches = match(
            `${option.bpID} - ${option.bpName}`,
            inputValue
          );
          const parts = parse(`${option.bpID} - ${option.bpName}`, matches);

          return (
            <React.Fragment>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                    className={clsx(part.highlight && classes.matchText)}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Carteira"
            InputProps={{
              ...params.InputProps,
              classes: {
                input: classes.autocompleteInput,
                underline: classes.autocompleteInputUnderline,
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: {
                root: classes.autocompleteInputLabelRoot,
                focused: classes.autocompleteInputLabel,
              },
            }}
          />
        )}
      />
      <Button color="inherit" component={Link} to={ROUTES.USER_ROUTES.HOME}>
        Início
      </Button>
    </div>
  );
};

const Header = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <AppBar
        color="primary"
        position="sticky"
        className={clsx(classes.appbar)}
      >
        <Toolbar style={{ height: "100%" }}>
          <Grid container justifyContent="space-between">
            <div>
              <IconButton
                onClick={() => setOpenDrawer((oldValue) => !oldValue)}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <ButtonsToolbarUser />
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={openDrawer} setOpen={setOpenDrawer} />
    </>
  );
};

export default Header;
