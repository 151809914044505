const ROUTES = {
  ADMIN_ROUTES: {
    VIEW_ACCESSES: "/admin/accesses",
  },
  USER_ROUTES: {
    HOME: "/user/",
    DASHBOARD: "/user/dashboard",
    EDIT_ORDER: "/user/edit-order",
    APPOINTMENT_TITLE: "/user/appointment/title",
    APPOINTMENT_INVOICES: "/user/appointment/invoices",
    APPOINTMENT_PRICE: "/user/appointment/price",
    APPOINTMENT_PRICE_CUSTOMER: "/user/appointment/price-customer",
    APPOINTMENT_SALES: "/user/appointment/sales",
    APPOINTMENT_MARKETING_PLAN: "/user/appointment/marketing-plan",
    APPOINTMENT_WALLET: "/user/appointment/wallet",
    CALL_CENTERS: "/user/call-centers",
    CHANGE_PASSWORD: "/user/change-password",
    ATIVE_COSTS: "/user/costs/active",
    HISTORY_COSTS: "/user/costs/history",
    SIMULATION_COSTS: "/user/costs/simulation",
    SETTINGS_COSTS: "/user/costs/settings-active",
    HISTORY_SETTINGS_COSTS: "/user/costs/settings-history",
    SALES_MARGIN: "/user/costs/sales-margin",
    FINANCIAL_DASHBOARD: "/user/financial/dashboard",
    FINANCIAL_INSTALLMENTS: "/user/financial/installments",
    QUOTE_SALE: "/user/quote-sale",
    NOTFOUND: "/user/not-found",
  },
};

export default ROUTES;
