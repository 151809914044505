import appConfig from "@config/appConfig";
import {
  StatusDoc,
  StatusInvoice,
} from "@modules/financial/dtos/IFinancialInstallmentsDTO";
import { StatusAtem } from "@modules/orders/dtos/IQueryOrderDataDTO";
import { SituationTitle } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { BASE_URLS } from "@pages/shared/baseUrlNewPortal";
import moment from "moment-timezone";
import rison from "rison";

import { v4 } from "uuid";

export function formatPercent(value: number, plates = 0) {
  return (value * 100).toFixed(plates) + "%";
}

export function formatToFixed(value: number, plates = 0) {
  return (value * 1).toFixed(plates);
}

export function calcPercentage(total: number, part: number) {
  return (part / total) * 100;
}

export const formatCurrencyPriceProduct = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 4,
  }).format(value);
};

export const formatDecimal = (value: number) => {
  return new Intl.NumberFormat("pt-BR").format(value);
};

export const formatCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    ...options,
  }).format(value);
};

export const formatCurrency4DecimalsPlace = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  }).format(value);
};

export const diffSet = (setA: string[], setB: string[]) => {
  let _diferenca = new Set(setA);
  for (let elem of setB) {
    _diferenca.delete(elem);
  }
  return _diferenca;
};

export function maskCPF(cpf: string): string {
  let masked = cpf.length > 11 ? cpf.slice(0, 11) : cpf;
  masked = masked.replace(/\D/g, "");
  masked = masked.replace(/(\d{3})(\d)/, "$1.$2");
  masked = masked.replace(/(\d{3})(\d)/, "$1.$2");
  masked = masked.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return masked;
}

export const maskCNPJ = (CNPJ: string) => {
  let masked = CNPJ.length > 14 ? CNPJ.slice(0, 14) : CNPJ;
  masked = masked.replace(/^(\d{2})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/\.(\d{3})(\d)/, ".$1/$2");
  masked = masked.replace(/(\d{4})(\d)/, "$1-$2");
  return masked;
};

export function documentMask(document: string): string {
  document = document.replace(/[-|.|/]/g, "").substring(0, 14);
  if (document.length > 11) {
    return maskCNPJ(document);
  }
  return maskCPF(document);
}

export const capitalizeWords = (words: string) => {
  return words
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const goToLogout = () => {
  const { hostname, origin } = window.location;
  const env = process.env.REACT_APP_ENV;
  const urlsLogout = appConfig.cognito.urlLogout;
  const isDev = env === "development" || hostname === "localhost";
  const isHomologation = Boolean(origin.split(".")[0].match(/-dev$/));

  window.location.href = isDev
    ? urlsLogout.development
    : isHomologation
    ? urlsLogout.homologation
    : urlsLogout.production;
};

export const getCorrectDate = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  const dateDtOnly = new Date(date.getTime() - userTimezoneOffset);

  return dateDtOnly;
};

export const nameStatusAtem = (statusAtem: StatusAtem) => {
  switch (statusAtem) {
    case "BL":
      return "Bloqueado";
    case "FT":
      return "Faturado";
    case "LIB":
      return "Liberado";
    case "DEV":
      return "Devolução";
    case "EST":
      return "Estornado";
  }
};

export const nameSituationTitle = (situationTitle: SituationTitle) => {
  switch (situationTitle) {
    case "closed":
      return "Pago";
    case "partial":
      return "Parcial";
    case "due":
      return "Vencido";
    case "open":
      return "A vencer";
  }
};

export const invoiceStatusName = (situationTitle: StatusInvoice) => {
  switch (situationTitle) {
    case "CLOSE":
      return "Pago";
    case "OPEN":
      return "Aberto";
  }
};

export const docStautusName = (situationTitle: StatusDoc) => {
  switch (situationTitle) {
    case "EXPIRED":
      return "Vencido";
    case "NOT_EXPIRED":
      return "A vencer";
    case "CLOSE":
      return "Pago";
    case "ACCUMULATED":
      return "Acumulado";
  }
};

export const getBgColorTotalCard = (situationTitle: StatusDoc) => {
  switch (situationTitle) {
    case "EXPIRED":
      return "#FBE3E3";
    case "NOT_EXPIRED":
      return "#FFF2D1";
    case "CLOSE":
      return "#C5E1F7";
    case "ACCUMULATED":
      return "#F0B4D7";
  }
};

export const getColorTotalCard = (situationTitle: StatusDoc) => {
  switch (situationTitle) {
    case "EXPIRED":
      return "#D91F05";
    case "NOT_EXPIRED":
      return "#FDC742";
    case "CLOSE":
      return "#1A74CD";
    case "ACCUMULATED":
      return "#9C1C67";
  }
};

export const getNumberStatusAtem = (statusAtem: StatusAtem) => {
  switch (statusAtem) {
    case "FT":
      return 0;
    case "BL":
      return 1;
    case "LIB":
      return 2;
    case "DEV":
      return 3;
    case "EST":
      return 4;
  }
};

export const downloadFile = (uriContent: string, name?: string | null) => {
  const link = document.createElement("a");
  link.href = uriContent;
  link.download = name || `Relatório-${moment().format("DD-MM-YYYY")}-${v4()}`;
  link.click();
};

export const fileNameByHeaders = (
  headers: Record<string, string | undefined>
) => {
  const headerContentDisposition =
    headers["content-disposition"] || headers["Content-Disposition"] || "";
  console.log({ headerContentDisposition });
  const match =
    headerContentDisposition.match(/.*"(.*)".*/) ||
    headerContentDisposition.match(/.*filename=(.*)/);
  if (match) {
    return match[1];
  }

  return null;
};

export const OnKeypressEscape = (action: () => void) => {
  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      action();
    }
  };

  document.addEventListener("keydown", keyDownHandler);

  return () => {
    document.removeEventListener("keydown", keyDownHandler);
  };
};

export interface EmbedUrlProps {
  /**
   * Exemplo: http://localhost:5601/jundcjsdc
   */
  host: string;
  /**
   * token para acessar o kibanana
   */
  token: string;
  /**
   * ID gerado pelo kibana para o grafico
   */
  dashboardId: string;
  timeFrom: Date | string;
  timeTo: Date | string;
  /**
   * String com a UI state do Kibana no padrao RISON
   */
  chartAppStateRison: string;
  /**
   * Para casos como tabela
   */
  useEncoding?: boolean;
  isDashboard?: boolean;
  filters?: {
    /**
     * Lista de customersID para filtrar resultados
     */
    customersID?: string[];
    /**
     * Lista de produtos para filtrar resultados
     */
    productsID?: string[];
    filialID?: string[];
    ufs?: string[];
    segments?: string[];
  };
}

interface EmbedUrlAdminProps extends EmbedUrlProps {
  /**
   * Tipo do usuario assessor
   */
  userType: "admin";
  /**
   * ID do assessor para filtrar resultados
   */
  bpID: string;
}

interface EmbedUrlAdvisorProps extends EmbedUrlProps {
  /**
   * Tipo do usuario assessor
   */
  userType: "advisorID";
  /**
   * Lista de ID de assessores para filtrar resultados
   */
  bpID: string[];
}

export const embedURL = (data: EmbedUrlAdminProps | EmbedUrlAdvisorProps) => {
  const timeFrom = data.timeFrom + "T04:00:00.000Z";
  const timeTo = data.timeTo + "T03:59:59.999Z";

  const {
    filters: decodedFilters,
    uiState,
    vis,
    ...decodeChartUiRison
  } = rison.decode<{
    filters: {}[];
    uiState: any;
    vis: any;
  }>(data.chartAppStateRison);

  const encodedGlobalRison = rison.encode_object({
    time: { from: timeFrom, to: timeTo },
  });
  const filters = [];
  if (data.userType !== "admin") {
    filters.push({
      $state: { store: "appState" },
      meta: {
        alias: null,
        disabled: false,
        index: "24208e40-14cc-11ec-aede-15a1754deff0",
        key: "order.advisorID.keyword",
        negate: false,
        params: data.bpID,
        type: "phrases",
        value: data.bpID.join(", "),
      },
      query: {
        bool: {
          minimum_should_match: 1,
          should: data.bpID.map((bpID) => {
            return {
              match_phrase: {
                "order.advisorID.keyword": bpID,
              },
            };
          }),
        },
      },
    });
  } else {
    if (data.bpID !== "0000000000") {
      filters.push({
        $state: { store: "appState" },
        meta: {
          alias: null,
          disabled: false,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.advisorID.keyword",
          negate: false,
          params: { query: data.bpID },
          type: "phrase",
        },
        query: {
          match_phrase: { "order.advisorID.keyword": data.bpID },
        },
      });
    }
  }

  if (data.filters) {
    const filterCommonMetaData = {
      alias: null,
      disabled: false,
      negate: false,
      type: "phrases",
    };

    if (data.filters.customersID && data.filters.customersID.length) {
      filters.push({
        $state: { store: "appState" },
        meta: {
          ...filterCommonMetaData,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.id_sap_customer",
          params: data.filters.customersID,
          value: data.filters.customersID.join(", "),
        },
        query: {
          bool: {
            minimum_should_match: 1,
            should: data.filters.customersID.map((customerID) => {
              return {
                match_phrase: {
                  "order.id_sap_customer": customerID,
                },
              };
            }),
          },
        },
      });
    }
    if (data.filters.productsID && data.filters.productsID.length) {
      filters.push({
        $state: { store: "appState" },
        meta: {
          ...filterCommonMetaData,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.productID",
          params: data.filters.productsID,
          value: data.filters.productsID.join(", "),
        },
        query: {
          bool: {
            minimum_should_match: 1,
            should: data.filters.productsID.map((productID) => {
              return {
                match_phrase: {
                  "order.productID": productID,
                },
              };
            }),
          },
        },
      });
    }
    if (data.filters.filialID && data.filters.filialID.length) {
      filters.push({
        $state: { store: "appState" },
        meta: {
          ...filterCommonMetaData,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.filialID",
          params: data.filters.filialID,
          value: data.filters.filialID.join(", "),
        },
        query: {
          bool: {
            minimum_should_match: 1,
            should: data.filters.filialID.map((filialID) => {
              return {
                match_phrase: {
                  "order.filialID": filialID,
                },
              };
            }),
          },
        },
      });
    }
    if (data.filters.ufs && data.filters.ufs.length) {
      filters.push({
        $state: { store: "appState" },
        meta: {
          ...filterCommonMetaData,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.state",
          params: data.filters.ufs,
          value: data.filters.ufs.join(", "),
        },
        query: {
          bool: {
            minimum_should_match: 1,
            should: data.filters.ufs.map((UF) => {
              return {
                match_phrase: {
                  "order.state": UF,
                },
              };
            }),
          },
        },
      });
    }
    if (data.filters.segments && data.filters.segments.length) {
      filters.push({
        $state: { store: "appState" },
        meta: {
          ...filterCommonMetaData,
          index: "24208e40-14cc-11ec-aede-15a1754deff0",
          key: "order.segment",
          params: data.filters.segments,
          value: data.filters.segments.join(", "),
        },
        query: {
          bool: {
            minimum_should_match: 1,
            should: data.filters.segments.map((segment) => {
              return {
                match_phrase: {
                  "order.segment": segment,
                },
              };
            }),
          },
        },
      });
    }
  }

  const encodedAppRison = rison.encode_object({
    filters: [...filters, ...decodedFilters],
    linked: false,
    query: { language: "kuery", query: "" },
    ...{
      uiState: uiState ? uiState : null,
      vis: vis ? vis : null,
      ...decodeChartUiRison,
    },
  });

  const url = new URL(data.host);
  url.pathname = `${data.token}/app/${
    data.isDashboard ? "dashboards" : "visualize"
  }/#/${data.isDashboard ? "view" : "edit"}/${data.dashboardId}`;
  // embed desabilita todas as funcoes do kibana, apenas fica os filtros
  url.searchParams.set("embed", "true");
  // estado global de variaveis
  url.searchParams.set("_g", `(${encodedGlobalRison})`);
  // estado de aplicacao do grafico, gerado pelo kibana
  url.searchParams.set("_a", `(${encodedAppRison})`);

  const urlFormatted = data.useEncoding
    ? encodeURI(decodeURIComponent(url.href))
    : decodeURIComponent(url.href);

  const formated = urlFormatted.replace(/0ESPACO0/g, " ");
  return formated;
};

/**
 * Verifica se a cidade pertence a zona franca
 */
export const checkCityZF = (cityName: string, centerID: string, UF: string) => {
  const cities = [
    "MANAUS",
    "PRESIDENTE FIGUEIRE",
    "TABATINGA",
    "RIO PRETO DA EVA",
  ];
  const centers = ["C001", "CDM1"];
  const ufs = ["AM"];

  const isZF =
    cities.some((city) => cityName.includes(city)) &&
    centers.some((center) => centerID === center) &&
    ufs.some((ufSelected) => UF === ufSelected);

  return isZF;
};

/**
 * Há um custo operacional para dois centros, que devem ser adicionados ao
 * preço do produto
 */
export const getOperacionalCost = (data: {
  branchName: string;
  filialID: string;
}) => {
  if (data.filialID === "CDM1") {
    if (data.branchName === "MECA") {
      return 0.07;
    }
    if (data.branchName === "MOISES") {
      return 0.03;
    }
  }
  return 0;
};

export const toReplaceAsterisk = (permission: string): string[] => {
  const permissionMethods = ["read", "write"];

  // Verifica se a string permission contém o caractere '*' sem compilar regex
  if (permission.includes("*")) {
    // Substitui '*' pelos métodos e retorna o array resultante
    return permissionMethods.map((method) => permission.replace(/\*/, method));
  }

  // Se não houver '*', retorna um array com a string original
  return [permission];
};

export const generatePermissionMatcher = (permissionRef: string) => {
  const exp = new RegExp(
    permissionRef
      .replace(/(:\*)/g, "(:(.+)?)?")
      .replace(/READ$/g, "(read|write)")
  );
  const r = (permissionRef: string) => Boolean(permissionRef.match(exp));
  return r;
};

export function getRedirectUrl() {
  const url = new URL(window.location.href);
  const hostname = url.hostname;

  switch (hostname) {
    case "comercial.grupoatem.com.br":
      return BASE_URLS.PRODUCTION;
    case "comercial-dev.grupoatem.com.br":
      return BASE_URLS.DEVELOPMENT;
    case "localhost":
      return BASE_URLS.LOCALHOST;
    default:
      console.error(
        `Ambiente não identificado para redirecionamento: ${hostname}`
      );
      return "#";
  }
}
