import Animations from "@components/Animations";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      background: "#C30C0C",
      position: "absolute",
      width: "100%",
      top: 0,
      left: 0,
      zIndex: theme.zIndex.appBar + 1,
    },
    content: {
      paddingTop: theme.spacing(8),
    },
    noPadding: {
      padding: 0,
    },
  })
);

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.noPadding}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Portal de vendas
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Animations />
      </div>
    </div>
  );
}
