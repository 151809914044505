import { UserAccessesPage } from "@pages/Admin/UserAccesses";
import { AppointmentMarketingPlan } from "@pages/Appointment/AppointmentMarketingPlan";
import { AppointmentPrice } from "@pages/Appointment/AppointmentPrice";
import { AppointmentPriceCustomer } from "@pages/Appointment/AppointmentPriceCustomer";
import { AppointmentSales } from "@pages/Appointment/AppointmentSales";
import { AppointmentWallet } from "@pages/Appointment/AppointmentWallet";
import { ChangePasswordPage } from "@pages/ChangePassword";
import { ActiveCostsPage } from "@pages/Costs/ActiveCosts";
import { HistoryCostsPage } from "@pages/Costs/HistoryCosts";
import { SimulationCostsPage } from "@pages/Costs/SimulationCosts";
import { HomePage } from "@pages/Home";
import { ActiveSettingsCostsPage } from "@pages/Costs/ActiveSettingsCosts";
import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import ROUTES from "../../config/routes";
import AppointmentInvoices from "../../pages/Appointment/AppointmentInvoices";
import AppointmentTitle from "../../pages/Appointment/AppointmentTitle";
import CallCenters from "../../pages/CallCenters";
import { HistorySettingsCostsPage } from "@pages/Costs/HistorySettingsCosts";
import { AppointmentSalesMargin } from "@pages/Costs/AppointmentSales";
import { FinancialDashboard } from "@pages/Financial/FinancialDashboard";
import { FinancialInstallments } from "@pages/Financial/FinancialInstallments";
import { QuoteSalePage } from "@pages/Home/QuoteSale";
import NotFoundPage from "@pages/NotFound";
import { generatePermissionMatcher } from "@utils/index";
import { TypesRolesPermissions } from "@context/UserContext/RolesPermissions";
import RouteGuard from "@components/Routes/RouteGuard";
import Welcome from "@pages/Welcome";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route
          path={ROUTES.USER_ROUTES.NOTFOUND}
          component={() => <NotFoundPage />}
        />

        <Route path={ROUTES.USER_ROUTES.HOME} exact component={Welcome} />
        <Route path={ROUTES.USER_ROUTES.DASHBOARD} exact component={HomePage} />

        {/* <Route path={ROUTES.USER_ROUTES.HOME} exact component={HomePage} /> */}

        <Route
          path={ROUTES.USER_ROUTES.CHANGE_PASSWORD}
          component={ChangePasswordPage}
        />

        {/* Consultar Preco */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_PRICE}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_price"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_PRICE
              )}
            >
              <AppointmentPrice />
            </RouteGuard>
          )}
        />

        {/* Consultar Preco de cliente */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_price_customer"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_PRICE_CUSTOMER
              )}
            >
              <AppointmentPriceCustomer />
            </RouteGuard>
          )}
        />

        {/* Simular Preco */}
        <Route
          path={ROUTES.USER_ROUTES.SIMULATION_COSTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_costs_simulation"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_SIMULATION_COSTS
              )}
            >
              <SimulationCostsPage />
            </RouteGuard>
          )}
        />

        {/* Consultar vendas */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_SALES}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_sales"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_SALES
              )}
            >
              <AppointmentSales />
            </RouteGuard>
          )}
        />

        {/* Consultar vendas/marketing */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_marketing_plan"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_MARKETING_PLAN
              )}
            >
              <AppointmentMarketingPlan />
            </RouteGuard>
          )}
        />

        {/* Consultar carteira */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_WALLET}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_wallet"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_WALLET
              )}
            >
              <AppointmentWallet />
            </RouteGuard>
          )}
        />

        {/* partidas individuais por cliente */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_title"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_TITLE
              )}
            >
              <AppointmentTitle />
            </RouteGuard>
          )}
        />

        {/* consultar NFs */}
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_INVOICES}
          component={() => (
            <RouteGuard
              flag="user_sidebar_appointment_invoices"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_APPOINTMENT_INVOICES
              )}
            >
              <AppointmentInvoices />
            </RouteGuard>
          )}
        />

        {/* consultar custos ativos */}
        <Route
          path={ROUTES.USER_ROUTES.ATIVE_COSTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_costs_active"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_ATIVE_COSTS
              )}
            >
              <ActiveCostsPage />
            </RouteGuard>
          )}
        />

        {/* consultar historico/agendado */}
        <Route
          path={ROUTES.USER_ROUTES.HISTORY_COSTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_costs_history"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_HISTORY_COSTS
              )}
            >
              <HistoryCostsPage />
            </RouteGuard>
          )}
        />

        {/* consultar configuração de margem ativas */}
        <Route
          path={ROUTES.USER_ROUTES.SETTINGS_COSTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_costs_settings_active"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_SETTINGS_COSTS
              )}
            >
              <ActiveSettingsCostsPage />
            </RouteGuard>
          )}
        />

        {/* consultar configuração de margem historico/agendado */}
        <Route
          path={ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_costs_settings_history"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_HISTORY_SETTINGS_COSTS
              )}
            >
              <HistorySettingsCostsPage />
            </RouteGuard>
          )}
        />

        {/* gerenciar Acessos dos Assessores */}
        <Route
          path={ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
          component={() => (
            <RouteGuard
              flag="user_sidebar_view_accesses"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_VIEW_ACCESSES
              )}
            >
              <UserAccessesPage />
            </RouteGuard>
          )}
        />

        <Route
          path={ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD}
          component={() => (
            <RouteGuard
              flag="user_sidebar_financial_dashboard"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_FINANCIAL_DASHBOARD
              )}
            >
              <FinancialDashboard />
            </RouteGuard>
          )}
        />

        {/* consultar Partidas */}
        <Route
          path={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
          component={() => (
            <RouteGuard
              flag="user_sidebar_financial_installments"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_FINANCIAL_INSTALLMENTS
              )}
            >
              <FinancialInstallments />
            </RouteGuard>
          )}
        />

        {/* consultar Venda Margem */}
        <Route
          path={ROUTES.USER_ROUTES.SALES_MARGIN}
          component={() => (
            <RouteGuard
              flag="user_sidebar_sales_margin"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_SALES_MARGIN
              )}
            >
              <AppointmentSalesMargin />
            </RouteGuard>
          )}
        />

        {/* consultar Cota */}
        <Route
          path={ROUTES.USER_ROUTES.QUOTE_SALE}
          // component={QuoteSalePage}
          component={() => (
            <RouteGuard
              flag="user_sidebar_quote_sale"
              rules={generatePermissionMatcher(
                TypesRolesPermissions.ROLE_QUOTE_SALE
              )}
            >
              <QuoteSalePage />
            </RouteGuard>
          )}
        />

        {/* Central de atendimento */}
        <Route path={ROUTES.USER_ROUTES.CALL_CENTERS} component={CallCenters} />

        <Redirect path="*" to={ROUTES.USER_ROUTES.HOME} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
