export enum TypesRolesPermissions {
  ROLE_SALES_MARKETING_ACCESS = "portal_comercial:sales-marketing-access", // permissao exclusiva
  ROLE_PRICING = "portal_comercial:pricing:*", // o mesmo que ROLE_COSTS

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_HOME = "portal_comercial:home:*",

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_SIMULATION_COSTS = "portal_comercial:costs-simulation:*", // simular preco

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_APPOINTMENT_PRICE = "portal_comercial:appointment:price:*", // consultar precos
  ROLE_APPOINTMENT_SALES = "portal_comercial:appointment:sales:*", // consultar vendas
  ROLE_APPOINTMENT_MARKETING_PLAN = "portal_comercial:appointment:marketing-plan:*", // consultar vendas/marketing
  ROLE_APPOINTMENT_PRICE_CUSTOMER = "portal_comercial:appointment:customer-price:*", // consultar preços de cliente
  ROLE_APPOINTMENT_WALLET = "portal_comercial:appointment:wallet:*", // consultar carteira
  ROLE_APPOINTMENT_TITLE = "portal_comercial:appointment:title:*", // partidas individuais por cliente
  ROLE_APPOINTMENT_INVOICES = "portal_comercial:appointment:invoices:*", // consultar NFs

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_COSTS = "portal_comercial:costs:*",
  ROLE_ADD_COAST = "portal_comercial:costs:add-price:*", // Cadastrar custos
  ROLE_ATIVE_COSTS = "portal_comercial:costs:active:*", // consultar custos ativos
  ROLE_HISTORY_COSTS = "portal_comercial:costs:history:*", // consultar historico / agendado
  ROLE_SETTINGS_COSTS = "portal_comercial:costs:settings-active:*", // consultar configuração de margem ativas
  ROLE_HISTORY_SETTINGS_COSTS = "portal_comercial:costs:settings-history:*", // consultar configuração de margem historico/agendado
  ROLE_ADD_CONFIG_MARGIN = "portal_comercial:costs:config-margin:*", // Cadastrar configuração de margem
  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_VIEW_ACCESSES = "portal_comercial:view-accesses:*", // gerenciar Acessos dos Assessores

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_TRIGGER_JOBS = "portal_comercial:trigger_jobs", // o mesmo que ROLE_SAP_REPPORT

  // --------------------------------------------------------------------------------------------------------------------------------
  ROLE_FINANCIAL_DASHBOARD = "portal_comercial:financial:dashboard:*",
  ROLE_FINANCIAL_INSTALLMENTS = "portal_comercial:financial:installments:*", // consultar Partidas

  ROLE_SALES_MARGIN = "portal_comercial:sales:margin:*", // Consultar venda margem
  ROLE_QUOTE_SALE = "portal_comercial:quote:sale:*", // Consultar cota

  ROLE_SCHEDULE = "portal_comercial:load-scheduling:*", // Agendamento Carregamento rodoviario
}

// --------------------------------------------------------------------------------------------------------------------------------
// ROLE_APPOINTMENT = "portal_comercial:appointment:*", // consultar // nomeação // compromisso

// --------------------------------------------------------------------------------------------------------------------------------
// ROLE_COSTS = "portal_comercial:costs:*",
// ROLE_PRICING = "portal_comercial:pricing:*", // o mesmo que ROLE_COSTS

// --------------------------------------------------------------------------------------------------------------------------------
// ROLE_SAP_REPPORT = "portal_comercial:sap-report:*", // enviar relatório SAP x ANP

// --------------------------------------------------------------------------------------------------------------------------------
// ROLE_FINANCIAL = "portal_comercial:financial:*", // financeiro

// --------------------------------------------------------------------------------------------------------------------------------
// ROLE_CALL_CENTERS = "portal_comercial:call-centers:*", // central de atendimento
// ROLE_CHANGE_PASSWORD = "portal_comercial:change-password:*", // trocar Senha
